import React from 'react'
import Logo from './logo'
import PrimaryMenu from './primaryMenu'

const Header = () => (
  <header id="header" className="full-header">
    <div id="header-wrap">
      <div className="container">
        <div className="header-row">
          <Logo />
          <PrimaryMenu />
        </div>
      </div>
    </div>
    <div className="header-wrap-clone" />
  </header>
)

export default Header
