import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

const LogoImage = () => {
  const data = useStaticQuery(graphql`
    query {
      logoImage: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fixed(height: 100) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
    }
  `)
  return <Img fixed={data.logoImage.childImageSharp.fixed} />
}

const Logo: React.FC = () => (
  <div id="logo">
    <a href="/">
      <LogoImage />
    </a>
  </div>
)

export default Logo
