import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons'
import { MenuContainer, MenuItem } from './menuItem'

const PrimaryMenu = () => {
  const [menuDisplayed, setMenuDisplayed] = useState(false)
  return (
    <>
      <div
        id="primary-menu-trigger"
        onClick={() => setMenuDisplayed(!menuDisplayed)}
      >
        <FontAwesomeIcon icon={menuDisplayed ? faTimes : faBars} />
      </div>
      <nav className="primary-menu">
        <MenuContainer extraClassNames={menuDisplayed ? ' d-block' : ''}>
          <MenuItem link={'/java'} title={'Java'} />
          <MenuItem link={'/kotlin'} title={'Kotlin'} />
          <MenuItem link={'/microservice'} title={'微服务'} />
          <MenuItem link={'/cloudnative'} title={'云原生'} />
          <MenuItem
            link={'https://space.bilibili.com/1094957548'}
            title={'我的B站'}
          />
          <MenuItem title={'个人作品'}>
            <MenuItem link={'/published-articles'} title={'技术文章'} />
            <MenuItem link={'/published-books'} title={'出版图书'} />
            <MenuItem link={'/self-published-books'} title={'自出版电子书'} />
          </MenuItem>
        </MenuContainer>
      </nav>
    </>
  )
}

export default PrimaryMenu
