import React from 'react'

type DataProps = {
  link?: string
  title: string
  children?: any
}

export const MenuItem: React.FC<DataProps> = ({ link, title, children }) => (
  <li className="menu-item">
    {link ? (
      <a className="menu-link" href={link}>
        <div>{title}</div>
      </a>
    ) : (
      <span className="menu-link">
        <div>{title}</div>
      </span>
    )}

    {children ? <SubMenuContainer>{children}</SubMenuContainer> : null}
  </li>
)

const SubMenuContainer = ({ children }) => (
  <ul className="sub-menu-container">{children}</ul>
)

export const MenuContainer = ({ children, extraClassNames }) => (
  <ul className={'menu-container ' + extraClassNames}>{children}</ul>
)
