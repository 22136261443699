import React from 'react'
import { ExternalLink } from 'react-external-link'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub } from '@fortawesome/free-brands-svg-icons'

const Footer = () => (
  <footer id="footer" className="dark">
    <div id="copyrights">
      <div className="container">
        <div className="row col-mb-30">
          <div className="col-md-6 text-center text-md-left">
            版权所有 &copy; {new Date().getFullYear()} 灵动代码
          </div>
          <div className="col-md-6 text-center text-md-right">
            <div className="d-flex justify-content-center justify-content-md-end">
              <ExternalLink
                href="https://github.com/VividCodeIO"
                className="social-icon si-borderless"
              >
                <FontAwesomeIcon icon={faGithub} />
              </ExternalLink>
              <ExternalLink
                href="https://github.com/alexcheng1982"
                className="social-icon si-borderless"
              >
                <FontAwesomeIcon icon={faGithub} />
              </ExternalLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
)

export default Footer
