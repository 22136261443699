import React from 'react'
import Helmet from 'react-helmet'

import '../css/bootstrap.css'
import '../css/style.css'
import '../css/dark.css'
import '../css/app.css'
import Header from '../components/header'
import Footer from '../components/footer'
import PageTitle from '../components/pageTitle'

type DataProps = {
  pageTitle?: string
  pageSubTitle?: string
  children: any
}

class Layout extends React.Component<DataProps> {
  render() {
    const { children, pageTitle, pageSubTitle } = this.props
    return (
      <>
        <Helmet>
          <html lang="en" />
          <script
            async
            src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5852717197941523"
            crossOrigin="anonymous"
          />
          <body className="stretched" />
        </Helmet>
        <div id="wrapper" className="clearfix">
          <Header />
          {pageTitle && <PageTitle title={pageTitle} subTitle={pageSubTitle} />}
          <section id="content">{children}</section>
          <Footer />
        </div>
      </>
    )
  }
}

export default Layout
