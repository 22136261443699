import React from 'react'
import SEO from './seo'

type DataProps = {
  title: string
  subTitle?: string
}

const PageTitle: React.FC<DataProps> = ({ title, subTitle }) => (
  <>
    <SEO title={title} />
    <section id="page-title">
      <div className="container clearfix">
        <h1>{title}</h1>
        {subTitle && <span>{subTitle}</span>}
      </div>
    </section>
  </>
)

export default PageTitle
